<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Kies je organisatie" />
    <h1>{{ $t('select-client-to-continue') }}</h1>
    <b-alert show variant="danger"
      ><svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="exclamation-circle"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        class="svg-inline--fa fa-exclamation-circle fa-w-16 fa-5x"
      >
        <path
          fill="currentColor"
          d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
          class=""
        ></path>
      </svg>
      {{ $t('account-bound-to-multiple-clients') }}
    </b-alert>
    <h2>{{ $t('select-a-client') }}</h2>
    <b-row class="pt-4">
      <b-col
        v-for="account in accounts"
        :key="account.ContactID"
        class="card-userswitch mt-4 mt-lg-0"
        xs="12"
        sm="12"
        md="12"
        lg="6"
        xl="4"
      >
        <b-card class="mb-4"
          ><p>
            {{ account.CompanyName }}
            <small>{{ account.FullNameWOSalutation }} </small>
          </p>
          <p></p>
          <b-button
            :value="account.ContactID"
            :disabled="!account.IsCustomerActive"
            @click="selectContactID"
            variant="primary"
            >{{ $t('continue-as') }} {{ account.CompanyName }}</b-button
          ></b-card
        >
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import Breadcrumbs from '@/components/header/BreadCrumbs'
import { copyCart } from '@/services/CartService'
export default {
  components: { Breadcrumbs },
  data() {
    return {}
  },
  computed: {
    accounts: function() {
      return this.$store.state.accounts
    }
  },
  methods: {
    async selectContactID(event) {
      await this.$store.commit('setContactID', {
        contactID: event.target.value
      })

      await copyCart()

      this.$router.push({
        name: 'index',
        params: { loggedIn: true, lang: this.$i18n.locale }
      })
    }
  }
}
</script>
